import accessToken from '@/services/cookies/accessToken';
import companyId from '@/services/cookies/companyId';
import sessionId from '@/services/cookies/sessionId';

const currentSession = sessionId.get();
if (!currentSession) {
  sessionId.set(new Date().getTime());
}

// eslint-disable-next-line import/prefer-default-export
export { accessToken, companyId, sessionId };
