<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-html="textBody" />
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    textBody: {
      type: String,
      default: '',
    },
  },
};
</script>
