<template>
  <VCol>
    <Component
      :is="matchBlockTypesComponents[block.renderType]"
      :image-src="imageSrc"
      :text-body="textBody"
      :title="blockTitle"
      :questions="blockQuestions"
      :user-answers="userAnswers"
      @update-required="updateRequired"
      @remove-required="removeRequired"
    />
  </VCol>
</template>

<script>
import { matchBlockTypesComponents } from '@/utils/constants';
import TextBlock from '@/components/blocks/TextBlock.vue';
import ImageBlock from '@/components/blocks/ImageBlock.vue';
import MatrixBlock from '@/components/blocks/MatrixBlock.vue';

export default {
  name: 'QuestionBlock',
  components: { TextBlock, ImageBlock, MatrixBlock },
  props: {
    block: {
      type: Object,
      default: () => ({}),
    },
    userAnswers: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      matchBlockTypesComponents,
    };
  },
  computed: {
    imageSrc() {
      return this.block.imageUrl;
    },
    textBody() {
      return this.block.body;
    },
    blockTitle() {
      return this.block.title;
    },
    blockQuestions() {
      return this.block.questions;
    },
  },
  methods: {
    updateRequired(questionId) {
      this.$emit('update-required', questionId);
    },
    removeRequired(questionId) {
      this.$emit('remove-required', questionId);
    },
  },
};
</script>
