<template>
  <VRow justify="center">
    <VCol
      lg="6"
      md="6"
      sm="10"
      data-test="image_block"
    >
      <VImg
        contain
        max-height="300"
        :src="imageSrc"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
  },
};
</script>
