var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"mb-6",attrs:{"align":"center","no-gutters":""}},[_c('VCol',{attrs:{"cols":_vm.desktopBreakpoints ? 4 : 12}},[_c('h3',{staticClass:"text-body-2",class:{ 'mb-3' : !_vm.desktopBreakpoints }},[_vm._v(" "+_vm._s(_vm.questionTitle)+" ")])]),_c('VCol',{attrs:{"cols":_vm.desktopBreakpoints ? (_vm.includeIfNotAnswered ? 7 : 8) : 12}},[(_vm.matrixMultipleAnswers)?_c('div',{staticClass:"d-flex flex-nowrap",class:{ 'flex-column align-start' : !_vm.desktopBreakpoints }},_vm._l((_vm.answers),function(answer){return _c('VCheckbox',{key:answer.id,staticClass:"ma-0 pa-0",class:{
          'mx-auto' : _vm.desktopBreakpoints,
          'mb-3' : !_vm.desktopBreakpoints
        },attrs:{"value":answer.id,"disabled":_vm.checkCheckbox(answer.id),"label":!_vm.desktopBreakpoints ? answer.text : null,"hide-details":"","off-icon":"$checkboxOffLight"},on:{"click":_vm.handleAnswer},model:{value:(_vm.checkboxModel),callback:function ($$v) {_vm.checkboxModel=$$v},expression:"checkboxModel"}})}),1):[_c('div',{staticClass:"d-flex flex-column flex-md-row"},_vm._l((_vm.answers),function(answer){return _c('div',{key:answer.id,staticClass:"cursor-pointer d-flex align-center",class:{
            'mx-auto' : _vm.desktopBreakpoints,
            'mb-2' : !_vm.desktopBreakpoints
          },on:{"click":function($event){return _vm.handleAnswer(answer.id)}}},[_c('VBtn',{staticClass:"ma-0",attrs:{"data-test":"tt-btn","outlined":"","fab":"","color":"tt-blue","width":"24","height":"24","ripple":_vm.$vuetify.breakpoint.lgAndUp}},[_c('VIcon',{directives:[{name:"show",rawName:"v-show",value:(answer.id === _vm.radioModel),expression:"answer.id === radioModel"}],attrs:{"color":"tt-blue","size":"8"}},[_vm._v(" $circleSolid ")])],1),(!_vm.desktopBreakpoints)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(answer.text)+" ")]):_vm._e()],1)}),0)]],2),(_vm.includeIfNotAnswered)?_c('VCol',{class:{ 'text-center' : _vm.desktopBreakpoints },attrs:{"cols":_vm.desktopBreakpoints ? 1 : 12}},[_c('VBtn',{class:_vm.notAnsweredOption ? 'tt-white--text' : 'tt-black--text',attrs:{"data-test":"tt-btn","fab":"","width":_vm.desktopBreakpoints ? 46 : 26,"height":_vm.desktopBreakpoints ? 46 : 26,"depressed":"","ripple":_vm.$vuetify.breakpoint.lgAndUp,"color":_vm.notAnsweredOption ? 'tt-red' : 'tt-black lighten-6'},on:{"click":_vm.toggleNotAnsweredOption}},[_c('VIcon',{attrs:{"size":_vm.desktopBreakpoints ? 20 : 15}},[_vm._v(" $close ")])],1),(!_vm.desktopBreakpoints)?_c('span',{staticClass:"ml-2"},[_vm._v(" Не могу оценить ")]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }